<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    max-width="1024"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
  >
    <v-card
      class="d-flex flex-column charcoal noscroll"
      v-if="loaded"
      v-touch:swipe.left="cancel"
      flat
      tile
    >
      <NotificationContainer />
      <BaseCloseDialogHeader heading="Invite Friends" @cancel="cancel" />
      <v-card-text class="silver--text pb-2 text-center">
        Ask your friend scan the QR code below
      </v-card-text>
      <template v-if="isAndroid()">
        <v-card-actions class="pa-0 py-2">
          <v-spacer />
          <BaseActionButton
            color="paper"
            text
            label="Android"
            icon="mdi-android"
            @clickedThis="setAndroid"
          />
          <BaseActionButton
            color="paper"
            text
            label="IOS"
            icon="mdi-apple"
            @clickedThis="setIOS"
          />
          <v-spacer />
        </v-card-actions>
        <v-card-actions class="py-2 paper">
          <v-spacer />
          <qrcode-vue
            background="#eaeaea"
            foreground="#212b31"
            :value="android ? valueAndroid : valueIOS"
            :size="size"
            level="H"
          />
          <v-spacer />
        </v-card-actions>
        <v-card-text class="py-0 mt-5 mb-5 text-center silver--text">
          {{
            android
              ? 'Scan QR code for Google Android.'
              : 'Scan QR code for Apple IOS.'
          }}
        </v-card-text>
      </template>
      <template v-else>
        <v-card-actions class="py-2 paper">
          <v-spacer />
          <qrcode-vue
            background="#eaeaea"
            foreground="#212b31"
            :value="valueGeneral"
            :size="size"
            level="H"
          />
          <v-spacer />
        </v-card-actions>
      </template>
      <v-spacer />
      <v-card-text
        :class="
          textSizeLarge + ' pt-0 charcoal--text text--lighten-2 text-center'
        "
      >
        OR
      </v-card-text>
      <v-card-actions
        :class="textSizeSmall + ' charcoal  silver--text py-0 px-4'"
      >
        <v-spacer />
        <span class="mx-2 --text">
          use one of your {{ invites.length }} invites.</span
        >
        <v-spacer />
      </v-card-actions>
      <v-card
        flat
        tile
        v-for="(invite, index) in invites"
        :key="index"
        class="charcoal d-flex flex-column"
      >
        <template v-if="index == 0">
          <v-sheet class="mx-2" rounded :style="charcoalTileMenu">
            <v-card-text class="pb-0 px-2">
              <v-text-field
                solo
                dark
                height="60"
                dense
                color="nicegreen"
                background-color="charcoal lighten-2"
                label="Friend's Name"
                name="name"
                v-model="invite.name"
                required
                type="text"
                prepend-inner-icon="mdi-account-circle"
                :counter="50"
                @blur="capitalizeName()"
              />
              <v-spacer />
              <v-text-field
               solo
                dark
                height="60"
                dense
                color="nicegreen"
                background-color="charcoal lighten-2"
                label="Email"
                type="email"
                name="email"
                v-model="invite.email"
                required
                prepend-inner-icon="mdi-email"
                :counter="250"
              />
            </v-card-text>
          </v-sheet>
          <v-spacer />
        </template>
      </v-card>
      <v-spacer />
      <v-card-actions v-if="invites.length > 0" class="charcoal pa-0">
        <v-btn
          :disabled="invites[0].email == null || invites[0].name == null"
          height="70"
          large
          dark
          label="Send Invite"
          color="progressActive"
          block
          tonal
          @click="sendInvite(invites[0])"
        >
          <v-icon color="black" large>
            mdi-send-circle-outline
          </v-icon>
          <span class="text-h6"> Send Invite</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import QrcodeVue from 'qrcode.vue'

const NotificationContainer = () =>
  import(/* webpackPrefetch: true */ '@/components/NotificationContainer.vue')
export default {
  mixins: [util],
  name: 'MyInvites',
  components: { NotificationContainer, QrcodeVue },
  data() {
    return {
      invites: [],
      loaded: false,
      snackbar: false,
      snackText: '',
      timeout: 2000,
      valueAndroid:
        'https://play.google.com/store/apps/details?id=com.bfitsquared.bfitr',
      valueIOS: 'https://apps.apple.com/us/app/bfitr/id1631562520',
      valueGeneral: 'https://bfitr.app/',
      size: 150,
      android: true
    }
  },
  computed: {
    ...appConfig
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  beforeMount() {
    this.loadInvites()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    setAndroid() {
      this.android = true
    },
    setIOS() {
      this.android = false
    },
    capitalizeName(name) {
      if (name != null) name = name.charAt(0).toUpperCase() + name.slice(1)
    },
    sendInvite(invite) {
      return axios
        .put(this.baseURL + '/invites', {
          invite: invite
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Invite was succesfully sent to ' + invite.name + '.')
            this.loadInvites()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadInvites() {
      return axios.get(this.baseURL + '/invites', {}).then(response => {
        if (response.status == 200) {
          this.invites = response.data.data
          this.invites.forEach(el => {
            el.email = null
            el.name = null
          })
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
